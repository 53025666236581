export default {
  namespaced: true,
  state: {
    type: null,
    query: {
      phone: null,
      source: null,
      coupon_code: null,
      qrcode: null,
      store_code: null,
      brand: null,
    },
  },
  getters: {
    queryData({query}) {
      return query;
    },
    couponType({type}) {
      return type;
    }
  },
  mutations: {
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_COUPON_TYPE(state, type) {
      state.query = type;
    },
    RESET_DATA(state) {
      state.query = {
        phone: null,
        source: null,
        coupon_code: null,
        qrcode: null,
        store_code: null,
        brand: null,
      }
      state.type = null
    }
  },
  actions: {
    setQuery({commit}, query, type) {
      commit('SET_QUERY', {
          phone: query.phone,
          source: query.source,
          coupon_code: query.coupon_code,
          qrcode: query.qrcode,
          store_code: query.store_code,
          brand: query.brand,
      })
      commit('SET_COUPON_TYPE', type)
    },
    resetQuery({commit}) {
      commit('RESET_DATA')
    },
  },
};
