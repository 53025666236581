import https from "./https";
import store from "@/store";

const register = {
  fetchMe() {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/general/me`);
  },

  updateMe(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.put(`${organization}/liff/general/me`, params);
  },

  updateMeV2(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.put(`${organization}/liff/general/me-v2`, params);
  },

  updateMeViaProfilePlus(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.put(`${organization}/liff/general/me-via-profile-plus`, params);
  },

  // 檢查是否啟用強制綁定 (module_config 位置： member_register.external_member_customer_bind.provider_config.force_bind)
  async checkForceBind() {
    const organization = store.state.liffGeneral.orgCode;
    let isEnabledForceBind = store.getters["liffModule/getConfig"]("member_register", "external_member_customer_bind.provider_config.force_bind") ?? false;

    let checkResponse = await https.get(`${organization}/liff/general/check-force-bind`);

    console.log(checkResponse);

    let execBindResult = checkResponse.data.exec_bind_result;
    /**
     * exec bind result 會有：
     * - not_enabled
     * - provider_error
     * - external_customer_not_found
     * - already_bound
     * - bind_success
     * - bind_fail
     */
    return {
      isEnabledForceBind,
      execBindResult,
    };
  },
};

export default register;
